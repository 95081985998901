<script>
import {isEmpty} from 'element-ui/src/utils/util';
import {getOferta} from '@/routes/api/views/flujo';
import ElOfertaDownSolicitudDefault from "./defaults/partsSolicitud/OfertaDownSolicitudDefault";
import ElOfertaUpSolicitudDefault from "./defaults/partsSolicitud/OfertaUpSolicitudDefault";
import ElOfertaUpDescuentosDefault from "./defaults/partsDescuentos/OfertaUpDescuentosDefault";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";

export default {
    name: 'PzBaseOfertas',
    components: {},
    mixins: [baseFormFlujo],
    provide: function () {
        return {
            ofertas: this
        }
    },
    data() {
        return {
            reference: 'otras_ofertas',
            elOfertaView: '',
            best: '',
            montos_array: '',
            cuotas_array: '',
            down: false,
            mycomponents: [ElOfertaUpSolicitudDefault.name, ElOfertaDownSolicitudDefault.name, ElOfertaUpDescuentosDefault.name],
        }
    },
    created() {
        this.$store.dispatch('loader/up', {trigger: this.$options.name})
        if (this.producto.needsDocuments) {
            this.getOfertaView('descuentos');
        } else {
            this.getOfertaView('solicitud');
        }
        getOferta(this.loan, {
            withAdditionals: this.extraData.withAdditionals,
        }).then((res) => {
            this.best = res.data;
            if (!isEmpty(res.data.adicionales)) {
                this.cuotas_array = res.data.adicionales.cuotas;
                this.montos_array = res.data.adicionales.montos;
                this.down = this.extraData.withAdditionals;
            }
        }).catch(() => {
            this.$router.push({name: 'Rejected'});
        }).finally(() => {
            this.$store.dispatch('loader/down', {trigger: this.$options.name})
        });
    },
   
    methods: {
        getOfertaView(tipe) {
            this.elOfertaView = tipe + '-' + this.extraData.ofertaView;
        }
    }
}
</script>


