<template>
  <el-row>
    <el-col
      :xl="{span: 12, offset:6 }"
      :lg="{span: 12 ,offset:6}"
      :md="{span: 12 ,offset:6}"
      :sm="{span: 18 ,offset:3}"
      :xs="{span: 18 ,offset:3}"
    >
      <el-card class="center-block">
        <div class=" pz-large-margin">
          <h2 class="text-center  pz-color-secondary">
            <span class="pz-font-size-xl pz-color-primary">
              {{ myForm.best.monto|currency(myForm.producto.moneda_prestamo.symbol, 0) }}
            </span>
            {{ $t('forms_flujo.oferta_up_solicitud_default.label_1') }}
            <span class="pz-font-size-xl pz-color-primary">
              {{ myForm.best.cantidad_cuota }}
            </span>
            {{ $t('forms_flujo.oferta_up_solicitud_default.label_2') }}
          </h2>
          <h2 class="text-center  pz-color-secondary">
            {{ $t('forms_flujo.oferta_up_solicitud_default.label_3') }}
            <span class="pz-font-size-xl pz-color-primary">
              {{ myForm.best.monto_cuota|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}
            </span>
            {{ myForm.producto.term_type.name }}
          </h2>
          <el-row class="center-block text-center">
            <el-col :span="24">
              <el-button @click="myForm.setOferta(true)">
                {{ $t('forms_flujo.oferta_up_solicitud_default.btn_accept') }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
    export default {
        name: 'PzOfertaUpSolicitudUy',
        inject: ['myForm'],
        mounted() {
        }
    }
</script>

