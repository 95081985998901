<template>
  <el-radio-group
    v-model="myForm.otras_ofertas.final"
    class="center-block"
  >
    <el-col
      :span="24"
      class="pz-large-margin"
    >
      <h3 class="text-center pz-large-margin pz-color-primary">
        {{ $t('forms_flujo.oferta_down_solicitud_default.label_1') }}
      </h3>
      <el-col
        v-for="(value, index) in myForm.montos_array"
        :key="index"
        :xl="{span: 20, offset:2 }"
        :lg="{span: 20 ,offset:2}"
        :md="{span: 20 ,offset:2}"
        :sm="{span: 20 ,offset:2}"
        :xs="{span: 20 ,offset:2}"
      >
        <el-radio
          :label="index"
          class="text-center"
          style="align-content: center"
        >
          <label class="pz-font-size-lg pz-color-primary">
            <span class="pz-font-size-xl pz-color-secondary">
              {{ value.monto|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}
            </span>
            {{ $t('forms_flujo.oferta_down_solicitud_default.label_2') }}
            <span class="pz-font-size-xl pz-color-secondary">
              {{ value.cuota }}
            </span>
            {{ $t('forms_flujo.oferta_down_solicitud_default.label_3') }}
            <span class="pz-font-size-xl pz-color-secondary">
              {{ value.monto_cuota|currency(myForm.producto.moneda_prestamo.symbol,0,{spaceBetweenAmountAndSymbol:true }) }}
            </span>
          </label>
        </el-radio>
      </el-col>
    </el-col>
    <el-col
      :span="24"

      class="pz-large-margin"
    >
      <h3 class="text-center pz-large-margin pz-color-primary">
        {{ $t('forms_flujo.oferta_down_solicitud_default.label_4') }}
      </h3>
      <el-col
        v-for="(value, index) in myForm.cuotas_array"
        :key="index+2"
        :xl="{span: 20, offset:2 }"
        :lg="{span: 20 ,offset:2}"
        :md="{span: 20 ,offset:2}"
        :sm="{span: 20 ,offset:2}"
        :xs="{span: 20 ,offset:2}"
      >
        <el-radio
          :label="index+2"
          class="text-center"
          style="align-content: center"
        >
          <label class="pz-font-size-lg pz-color-primary">
            <span class="pz-font-size-xl pz-color-secondary">
              {{ value.monto|currency(myForm.producto.moneda_prestamo.symbol, 0,{ spaceBetweenAmountAndSymbol:true }) }}
            </span>
            {{ $t('forms_flujo.oferta_down_solicitud_default.label_2') }}
            <span class="pz-font-size-xl pz-color-secondary">
              {{ value.cuota }}
            </span>
            {{ $t('forms_flujo.oferta_down_solicitud_default.label_3') }}
            <span class="pz-font-size-xl pz-color-secondary">
              {{ value.monto_cuota|currency(myForm.producto.moneda_prestamo.symbol, 0 ,{ spaceBetweenAmountAndSymbol:true }) }}
            </span>
          </label>
        </el-radio>
      </el-col>
    </el-col>
  </el-radio-group>
</template>
<script>
    export default {
        name: 'PzOfertaDownSolicitudUy',
        inject: ['myForm'],
        mounted() {
        }
    }
</script>

